.value-ellipsis{
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    max-width: 200px;
    font-size: 14px !important;
    font-weight: 500;
}
.table-paginate{
    display: flex;
    justify-content: center;
    margin-top: 30px;
}
.page-link{
    padding: 2px 10px !important;
    background-color: transparent !important;
    /* border: none !important; */
    cursor: pointer;
    color: #000000d4 !important;
}
.active{
    background-color: #454649 !important;
    border-color: #454649 !important;
}
.active .page-link{
    color: #ffffff !important;
    border-color: #000000d4 !important;
}
.page-link.disabled{
  background-color: #80808029 !important;  
}
.session-user{
    width: 90%;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f2f2f2;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.userdetail{
    margin: 5px;
    font-size: 16px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    /* color: #000000d4 !important; */
    /* font-weight: 600; */
}
.header{
    border-bottom: none !important;
}

.table-wrap thead th{
    background: #282828;
    color: white;
}