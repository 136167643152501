body {
  margin: 0;
  font-family: AeonikRegular, 'Open Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-right: 0px !important;
  overflow: auto !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  opacity: 15%;
  border-radius: 5px 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3c4b64;
  border-radius: 5px 5px;
}


/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: transparent;
}
