@import '../../styles/colors';

.authority-details-container {
  min-width: 400px;

  .block-heading {
    display: flex;
    justify-content: flex-start;
    margin-top: 28px;
    margin-bottom: 8px;
  }

  .authority-request-header {
    color: $primary-blue;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    /* 125% */
  }

  .organization-network-request {
    display: flex;
    background: #f8f8f8;
    gap: 16px;
    padding: 14px;
    margin-bottom: 12px;

    .content-wrap {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .organization-request-name {
        color: var($primary-blue);
        font-size: 16px;
        font-weight: 600;
        line-height: 16px;

        @media screen and (max-width: 400px) {
          font-size: 14px;
          line-height: 14px;
        }
      }

      .organization-request-email {
        color: var($primary-blue);
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;

        @media screen and (max-width: 400px) {
          font-size: 12px;
          line-height: 12px;
        }
      }

      .organization-name {
        color: var($primary-blue);
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
        /* 114.286% */
      }
    }
  }

  .comment-box {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    background: #f8f8f8;
    gap: 10px;
    padding: 14px;

    .heading-display {
      color: #000000;
      font-weight: 600;
      font-size: 14px;
    }

    .comment {
      color: var($primary-blue);
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      text-align: justify;
      word-wrap: break-word;
      word-break: break-word;
      hyphens: auto;
      -ms-hyphens: auto;
      -moz-hyphens: auto;
    }

    .comment-date {
      font-size: 12px;
    }
  }

  .network {
    margin-top: 28px;
    margin-bottom: 18px;
    display: flex;
    justify-content: flex-start;
  }

  .network-request {
    flex-direction: column;
    gap: 7px;
    margin-bottom: 10px;

    .detail-wrap {
      display: flex;
      gap: 16px;
    }

    .reciever {
      padding-top: 5px;
      padding: 0px;
    }
  }
}