.Toastify {
  .Toastify__close-button {
    display: block;
  }
  .Toastify__close-button > svg{
    fill: white;
  }
  .Toastify__toast-container {
    text-align: center;
  }
  .Toastify__toast-icon > svg{
    fill: white;
  }
  .Toastify__toast--info {
    background: #000000;
    border-radius: 4px;
    font-weight: bold;
    color: white;
    font-size: 15px;
  }
  .Toastify__toast--success {
    background: #0B1F3E;
    border-radius: 4px;
    font-weight: bold;
    color: white;
    font-size: 15px;
  }
  .Toastify__toast--error {
    background: #ED3B41;
    border-radius: 4px;
    font-weight: bold;
    color: white;
    font-size: 15px;
  }
  .Toastify__toast--success {
    div{
      word-break: break-word;
    }
  } 
  .Toastify__toast--error {
    div{
      word-break: break-word;
    }
  }
}
