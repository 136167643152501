.input-group-text-icon {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .bg-color{
    background: linear-gradient(140.56deg, rgb(30, 30, 30) 22.57%, rgb(0, 0, 0) 79.47%) !important;
  }
  .bg-sidebar{
    background: black !important;
  }
  .emailField .form-control{
    border-bottom-right-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }