.search_field_wrapper{
    width: 240px;
    height: 100%;
    border: 1.5px solid #3c4b64;
    border-radius: 8px;
    padding: 6px 16px;
    display: flex;
    justify-content: flex-end;

    
}
.search_field_wrapper input{
    border: none ;
    outline:none;
    width: calc(100% - 24px);
    background: rgb(235, 237, 239,1);
    font-size: 12px;

}
   
.dropdown-item:active {
    background-color: transparent !important;
    color: black !important;
}
.dropdown-item:focus-visible{
    outline: white !important;
}
.dropdown-item:hover{
    border-radius: 5px !important;
}