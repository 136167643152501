.dashboard-container {
    margin-top: 30px;
  }
  
  .row-container {
    margin-bottom: 20px;
  }
  
  .section-box {
    flex: 1;
    padding: 20px;
    border-radius: 8px;
    gap: 20px;
    width: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f2f2f2;
    border: 1px solid #ccc;
    margin-bottom: 20px;
  }

    .section-box_user {
      padding: 20px;
      border-radius: 8px;
      gap: 20px;
      width: fit-content;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      background-color: #f2f2f2;
      border: 1px solid #ccc;
      margin-bottom: 20px;
    }

    .section-export-button{
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        background-color: #f2f2f2;
        border: 1px solid #ccc;
        margin-bottom: 20px;
      padding: 10px;
      border-radius: 8px;
      cursor: pointer;
      font-weight: 600;
    }
        .section-export-button:hover {
          background-color: #beb6b6af;
        }
  
  .chart-container {
    margin-top: 20px;
  }
  .dropdown-container {
    display: flex;
    justify-content: flex-end;
  }

  .dropdown-select {
    padding: 5px;
    border-color: transparent;
    border-radius: 7px;
    font-size: 12px;
  }

  .ml-10{
    margin-left: 10px;
  }
  
  @media screen and (max-width: 1200px) {
    .section-box{
      width: 400px;
    }
  }
  @media screen and (max-width: 1000px) {
    .section-box{
      width: 300px;
    }
  }
  @media screen and (max-width: 600px) {
    .row-container {
      display: block;
    }
    .section-box{
      width: 300px;
    }
  }